import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login",

    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/kassa",
    name: "Kassa",
    component: () => import("@/views/Kassa.vue"),
  },

  {
    path: "/cashout",
    name: "CashOut",
    component: () => import("@/views/CashOut.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("@/views/Orders.vue"),
  },
  {
    path: "/storage",
    name: "Storage",
    component: () => import("@/views/Storage.vue"),
  },
  {
    path: "/cook",
    name: "Cook",
    component: () => import("@/views/Cook.vue"),
  },
  {
    path: "/storagein",
    name: "StorageIn",
    component: () => import("@/views/StorageIn.vue"),
  },
  {
    path: "/ask",
    name: "Ask",
    component: () => import("@/views/Ask.vue"),
  },
  {
    path: "/closeday",
    name: "Closeday",
    component: () => import("@/views/Closeday.vue"),
  },
  {
    path: "/devices",
    name: "Devices",
    component: () => import("@/views/Devices.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
