import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import H from "@/scripts/helper";
//import * as bootstrap from "bootstrap";
import Swal from "sweetalert2";
import NProgress from "nprogress";
// import VueLazyLoad from "vue3-lazyload";
import { createPinia } from "pinia";
import Vue3TouchEvents from "vue3-touch-events";
import PrimeVue from "primevue/config";
import Popper from "vue3-popper";
//Формат даты
// import VueDatePicker from '@vuepic/vue-datepicker';
// import "./registerServiceWorker";
import "nprogress/nprogress.css";
import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap/dist/js/bootstrap.bundle.min";
//import "bootstrap/dist/js/bootstrap";
import "@/css/main.css";
// import "@/css/adminlte.css";
import "@/css/fortawesome/css/all.css";
import "@/css/fortawesome6/css/all.css";
import "primevue/resources/themes/md-dark-indigo/theme.css";

// import "@vuepic/vue-datepicker/dist/main.css";
const Vue = createApp(App);
Vue.use(
  router,
  //bootstrap,
  Swal,
  NProgress
  // VueDatePicker
);
import VueLazyLoad from "vue3-lazyload";
Vue.use(VueLazyLoad, {
  error: "https://admin.holderpos.com.ua/img/addimg.png",
  loading: "https://admin.holderpos.com.ua/loader.gif",
  observer: true,
});
Vue.use(Vue3TouchEvents, {
  disableClick: false,
});
// Vue.component('VueDatePicker', VueDatePicker);
Vue.use(PrimeVue, { unstyled: true });
Vue.use(createPinia());
Vue.component("Popper", Popper);
Vue.mount("#app");
